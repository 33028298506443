<template>
  <b-jumbotron>
    <b-container>
      <b-row>
        <b-col md="3" class="mb-2">
          <b-avatar variant="none" size="18rem" :src="avatar" />
        </b-col>
        <b-col md="9">
          <h1>Петров Антон</h1>
          <p>Full Stack Web Developer</p>
          <b-button
            variant="danger"
            href="/api/summary/"
            @click.prevent="downloadSummary()"
            >Скачать резюме <b-icon-file-earmark-person
          /></b-button>
          <hr />
          <div class="d-flex flex-row justify-content-center">
            <a
              :href="link.link"
              v-for="(link, i) in $store.state.links"
              :key="`link-${i}`"
            >
              <img :src="link.logo" style="max-width: 2em" class="mx-2" />
            </a>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-jumbotron>
</template>

<script>
import Axios from "axios";
export default {
  data() {
    return {
      avatar: require("../assets/avatar.png"),
      gitlab_logo: require("../assets/gitlab_logo.png"),
      vk: require("../assets/vk.png"),
    };
  },
  async beforeMount() {
    await this.$store.dispatch("setLinks");
  },
  methods: {
    downloadSummary() {
      Axios.get("/api/summary/", { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "Петров_Антон.pdf";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
  },
};
</script>

<style>
</style>