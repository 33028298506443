<template>
  <div>
    <main-about-me />
    <b-container>
      <b-row>
        <b-col>
          <list-card
            header="Примеры моих работ"
            :data="$store.state.examples"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md='6'>
          <list-card
            header="Участие в хакатонах"
            :data="$store.state.hacks"
          />
        </b-col>
        <b-col md='6'>
          <list-card
            header="Участие в проектах"
            :data="$store.state.projects"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md='6'>
          <list-card
            header="Освоенные языки программирования"
            :data="$store.state.codes"
          />
        </b-col>
        <b-col md='6'>
          <list-card
            header="Освоенные технологии и фреймворки"
            :data="$store.state.skills"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ListCard from "../components/ListCard.vue";
import MainAboutMe from "../components/MainAboutMe.vue";
import { mapActions } from "vuex";
export default {
  components: { MainAboutMe, ListCard },
  async beforeMount() {
    await this.setAchievements();
    await this.setExamples();
    await this.setSkills();
  },
  methods: {
    ...mapActions(["setAchievements", "setExamples", "setSkills"]),
  },
};
</script>

<style>
</style>