<template>
  <b-card no-body :header="header" class="my-2">
    <b-list-group flush>
      <b-list-group-item v-for="d in data" :key="d.name" :href="d.url ? d.url : ''">{{d.name}}</b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
export default {
    props:{
        header: String,
        data: Array,
    }
};
</script>

<style>
</style>